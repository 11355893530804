@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind typography;
@tailwind forms;
@tailwind aspect-ratio;

@import "~react-image-gallery/styles/css/image-gallery.css";

body {
    font-family: "Montserrat";
  }